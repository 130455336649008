import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Autocomplete,
  TextField,
  Typography,
  Avatar,
  CardHeader,
  Divider,
  AlertColor,
} from "@mui/material";
import useCommentTree from "./useCommonTree";
import {addSeederCommentAction, getSeederChatData,deleteSeederComment, getSeederAction} from '../../../app/seeders/seeders'
import CommentList from './CommentList'; 
import {CommentData} from './CommentList';
import Loader from "../../Loader/Loader";
import Toaster from "../../Toaster/Toaster";
import { FormControlLabel, Checkbox } from '@mui/material';
import { SeederUsersList } from "../../../../types/common";
import BasicDateTimePicker from "../DateTimepicker/DateTimePicker";
import dayjs, { Dayjs } from 'dayjs';
import { seederSchema } from "../../../schema/seeders";
import { schedulePostv2 } from "../../../app/postSchedulerv2/postSchedulerv2";

// Main App component
const Index = ({commentData ,postDateAndTime , handlePostEdit, closeCommentModal}) => {
  const [author, setAuthor] = useState<any>(null);
  const [commentText, setCommentText] = useState<string>("");
  const [update, setUpdate] = useState<boolean>(false);
  const [data, setData] = useState<any>()
  const [commentDetail, setCommentDetail]=useState<any>([]);
  const [loading , setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [postDate, setPostDate] = useState(null);
  const [seederDetails , setSeederDetails] = useState<any>(null);
  const [severity , setSeverity] = React.useState('');
  const [rowCount,setRowCount]= useState<number>(0);
  const [filteredValue,setFilteredValue]= useState<string>("");
  const [lastCommentDateTime, setLastCommentDateTime] = useState<Dayjs | null>(null);
  const {comments,insertComment,editComment,deleteComment} = useCommentTree([]);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const [isAlias,setIsAlias]= useState(false);

  useEffect(()=>{
    getSeederAction(pagination , filteredValue)
    .then((res) =>{
      if(!res.status){
      }else{
        setData(res?.data?.seederCandidateListResponseDtos ? res?.data?.seederCandidateListResponseDtos : []);
        setRowCount(res?.data?.totalCount)
      }    
    })
    .catch((err) =>{
    })
 
  },[commentData,update , pagination , filteredValue ])
  const handleScroll = (event , setPage , rowCount? ,paginationSize?) => {
    const bottom =
      event.target.scrollHeight === event.target.clientHeight + event.target.scrollTop;
      if (bottom && paginationSize < rowCount) {
        setPage();
      }
  };
  function setPage(pageSize){
    setpagination((prev) =>{ return {...prev , pageSize:pageSize ? pageSize : prev.pageSize + 10}})
  }
const handleCommentSubmit = (e) => {
  e.preventDefault();
  const date:any = lastCommentDateTime ? dayjs(lastCommentDateTime) :  dayjs(postDateAndTime).add(1,'minute')
  const d = date?.$d
  if(commentText){
    const payload = {
      candidateLinkedInId:seederDetails?.id || "",
      isAlias:isAlias,
      timeZone: Intl?.DateTimeFormat().resolvedOptions().timeZone ,
      author: author,
      photoUrl: seederDetails?.photoUrl,
      date:lastCommentDateTime ? dayjs(lastCommentDateTime) :  dayjs(postDateAndTime).add(1,'minute'),
      dateTime:dayjs(d).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      checkingDate : postDateAndTime,
      postSchedulerId:'',
      comment:commentText,
      anonName: seederDetails?.anonymousName ,
      aliasUrl:seederDetails?.aliasUrl
    }
    insertComment(undefined,commentText,payload);
    setCommentText('');
    setLastCommentDateTime(null)


  }
  

};
//call post api
const addPost = async(values:any) =>{
  setLoading(true)
const date = values?.dateTime?.$d;
let url = values['postUrl'] ? values['postUrl'] .split(',') : ""
values['postUrl'] = url[1]
const payload = {
  body:values?.body || "",
  candidateLinkedInId:values?.id || "",
  communityId:values?.communityId || "",
  dateTime:values?.dateTime ? dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
  isAlias:values?.isAlias || false,
  postUrl:values?.postUrl || null,
  promptType:'PROMPT',
  timeZone :values?.dateTime ? Intl.DateTimeFormat().resolvedOptions().timeZone : null,
  title:values?.title || "",
  comments:comments
}
  // setLoading(true)
  //Call Api To Add User
   schedulePostv2(payload)
    .then((res) =>{
    if(!res?.status){
      setSeverity('error')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Request failed due to duplicity')
    }else{
      setSeverity('success')
      setOpenAlert(true)
      closeCommentModal() 
      setMessage(res?.message ? res.message : 'Successfully Posted')
      // setToggle(!toggle)
    }
    })
    .catch((err) =>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : 'Error')
    })
}

  const addReply = (commentId: string, replyText: string, username: string, aliasVal: boolean , seederDID , date:any,photoUrl:any , checkDate:any) => {
    if(replyText){
      const replyDate:any = date
      const d = replyDate?.$d
      const payload = {
        author:username,
        candidateLinkedInId:seederDID || "",
        isAlias:aliasVal || false,
        dateTime:dayjs(d).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone,
        date:dayjs(date) ,
        photoUrl:photoUrl,
        commentSchedulerId:"",
        reply:replyText || "",
        checkingDate:checkDate
      }
      insertComment(commentId,replyText,payload);
    }
  };
  


const updateComments = (commentId: string, newReply: any) => {
  setCommentDetail((prev) => {
    return prev.map((comment) => 
      comment.id === commentId
        ? { ...comment, replies:  [...(comment.feedCommentResponseDtoResponse?.list || []), newReply] }
        : comment
    );
  });
};
  return (
    <>
    <Grid container spacing={1} padding={2}>
      <Grid  item xs={12} sm={12}>
      <Box sx={{ maxWidth: 600, mx: "auto", mt: 5 }}>
      <Card>
  <Box
  sx={{
    display:'flex',
    justifyContent:'space-between'
  }}
  >
  <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            <img 
              src={commentData?.isAlias ? commentData?.aliasUrl : commentData?.photoUrl} 
              alt="photo" 
              style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
            />
          </Avatar>
        }
        title={`${commentData?.communities ? commentData?.communities[0]?.communityName : ""}`}
        subheader={commentData?.isAlias ? commentData?.
          anonymousName
           :commentData?.fullName}
      />
      <Box
      sx={{
        display:'flex',
        alignItems:'flex-start'
      }}
      >
      <Button sx={{marginRight:'5px'}} onClick={handlePostEdit} variant="contained">Edit</Button>
      <Button  variant="contained" onClick={() => {addPost({...commentData , commentDetail})  }}>Schedule Post</Button>
      </Box>
  </Box>
      <Divider />
      <CardContent>
        {/* <Typography variant="body1" color="text.secondary">
          {commentData?.title || 'no data'} 
        </Typography> */}
        <Typography variant="body1" color="text.primary">
          {commentData?.body || 'no data'} 
        </Typography>
      </CardContent>
      <CardContent>
        <Typography variant="h6" color="text.primary">
          {`Scheduled For: ${postDateAndTime ? postDateAndTime : ""}`|| 'no data'} 
        </Typography>
      </CardContent>
    </Card>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
    <Box sx={{ maxWidth: 600, mx: "auto", mt: 5 }}>
      <Card>
        <CardContent>
      <Typography variant="h4" gutterBottom>
        Comments as Seeder
      </Typography>
   
      <form onSubmit={handleCommentSubmit}>
        <Grid container spacing={1} padding={2}>
            <Grid item xs={12} sm={12}>
            <Typography variant="h6" gutterBottom>
        Choose Seeder
      </Typography>
        <Autocomplete
              disablePortal
              options={data ? data : []}
              fullWidth
              
              ListboxProps={
                {
                  onScroll:(e) => handleScroll(e , setPage , rowCount , pagination?.pageSize)
                }
              }
              getOptionLabel={(option:any) => `${option?.fullName} (${option?.anonymousName || ''})`}
              onChange={(event:any, newValue) => {
                if (newValue && typeof newValue === 'object' && 'fullName' in newValue) {
                  const { fullName } = newValue;
                  setAuthor(fullName);
                  setSeederDetails(newValue ? newValue : null) // Set the author's name if valid
                } else {
                  setAuthor(null); // Reset author if the value is null or invalid
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  onChange={(e) => setFilteredValue(e?.target?.value)}
                  // name="status"
                  label="Seeders"
                />
              )}
              value={data?.find((item) => item.fullName === author) || null}
            />
            </Grid>
            <Grid item xs={12} sm={12}>
        <TextField
          label="Add a comment"
          variant="outlined"
          fullWidth
          value={commentText}
          minRows={5} 
          onChange={(e) => setCommentText(e?.target?.value)}
          sx={{ mt: 2 }}
        />

<FormControlLabel
          control={<Checkbox
            id="isAlias"
            checked={isAlias}
            onChange={()=>{
              setIsAlias((prv)=>!prv)
            }}
            color="primary"
          />}
          label="Use Alias"
        />

        </Grid>
        <BasicDateTimePicker
                        previousDateAndTime={postDateAndTime}
                        onDateTimeChange={(dateTime) => setLastCommentDateTime(dayjs(dateTime))}
        />
        </Grid>
        
        <Button type="submit" variant="contained">
          Post Comment
        </Button>
      </form>
      </CardContent>
      </Card>
      <CommentList handleScroll={handleScroll} rowCount={rowCount} updateComments={updateComments} comments={comments} addReply={addReply} userList={data} deleteData={deleteComment} editComment={editComment}/>
    </Box>
    </Grid>
    </Grid>
      <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
  </>
  );
};
export default Index;




